import {IconButton, Typography, useMediaQuery} from '@mui/material';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import React from "react";
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import { scrollIntoView } from "seamless-scroll-polyfill";


const TripsToOregon = ({ isPreview }) => {
    const [open, setOpen] = React.useState(false);

    const openDrawer = (value) => () => {
        setOpen(value);
    };

    const isMobile = useMediaQuery('(max-width:768px)');

    const fRef = React.useRef(null);
    const f1Ref = React.useRef(null);
    const f2Ref = React.useRef(null);
    const f3Ref = React.useRef(null);
    const f4Ref = React.useRef(null);
    const f5Ref = React.useRef(null);
    const f6Ref = React.useRef(null);
    const f7Ref = React.useRef(null);
    let fRefs=[f1Ref, f2Ref, f3Ref, f4Ref, f5Ref, f6Ref, f7Ref];
    const sRef = React.useRef(null);
    const s1Ref = React.useRef(null);
    const s2Ref = React.useRef(null);
    const s3Ref = React.useRef(null);
    const s4Ref = React.useRef(null);
    const s5Ref = React.useRef(null);
    const s6Ref = React.useRef(null);
    const s7Ref = React.useRef(null);
    const s8Ref = React.useRef(null);
    const endRef = React.useRef(null);
    let sRefs=[s1Ref, s2Ref, s3Ref, s4Ref, s5Ref, s6Ref, s7Ref, s8Ref];

    const scrollToSection = (ref) => {
        if (ref.current) {
            scrollIntoView(ref.current, {
                behavior: "smooth",
                block: "start",
            },{
                duration: 250
            });
        }
    };

    const menuDrawer = (
        <Box sx={{ width: isMobile ? 200 : 300 }} onClick={openDrawer(false)}>
            <List>
                <ListSubheader>
                    <ListItemButton onClick={() => scrollToSection(fRef)}>
                        <ListItemText primary={'February Trip'} className="listFatherSubheader" disableTypography/>
                    </ListItemButton>
                </ListSubheader>
                {['Day 1 Eugene-Crescent City',
                    'Day 2 Redwood NP',
                    'Day 3 Lassen Volcanic NP',
                    'Day 4 Mt. Shasta',
                    'Day 5 Crater Lake NP',
                    'Day 6 Oakridge',
                    'Day 7 Back home'].map((text, index) => (
                    <ListItem disablePadding key={'F' + index}>
                        <ListItemButton onClick={() => scrollToSection(fRefs[index])}>
                            <ListItemText primary={text} className="listChildButton" disableTypography/>
                        </ListItemButton>
                    </ListItem>
                ))}
                <ListSubheader>
                    <ListItemButton onClick={() => scrollToSection(sRef)}>
                        <ListItemText primary={'September Trip'} className="listFatherSubheader" disableTypography/>
                    </ListItemButton>
                </ListSubheader>
                {['Day 1 Arrive',
                    'Day 2 With Scott',
                    'Day 3 Mt. Hood',
                    'Day 4 Columbia River Gorge & Cannon Beach',
                    'Day 5 Highway 101',
                    'Day 6 Crater Lake NP',
                    'Day 7 Two Amazing Humans',
                    'Day 8 Leaving'].map((text, index) => (
                    <ListItem disablePadding key={'S ' + index}>
                        <ListItemButton onClick={() => scrollToSection(sRefs[index])}>
                            <ListItemText primary={text} className="listChildButton" disableTypography/>
                        </ListItemButton>
                    </ListItem>
                ))}
                <ListSubheader>
                    <ListItemButton onClick={() => scrollToSection(endRef)}>
                        <ListItemText primary={'The End'} className="listFatherSubheader" disableTypography/>
                    </ListItemButton>
                </ListSubheader>
            </List>
        </Box>
    );

    return (
        <Box>
            {isPreview &&
                <Box className="previewCard">
                    <Grid container style={{height: '100%'}}>
                        <Grid item xs={4}>
                            <Box
                                component="img"
                                className='previewImage'
                                src={`${process.env.PUBLIC_URL}/blog/oregon/0.jpg`}
                            />
                        </Grid>
                        <Grid item xs={8} sx={{padding: 2}} className='previewText'>
                            <Typography variant={isMobile ? "h6":"h5"} className='previewTitle'>Trips to Oregon</Typography>
                            <Typography variant="body2" className='previewDate' color="textSecondary">
                                September 28, 2024
                            </Typography>
                            <Typography variant="body1" className='previewContent'>
                                The thought of going to Oregon started with the simplest idea of wanting to see Crater Lake.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            }
            {!isPreview &&
                <Box className="content" sx={{width: isMobile ? '90%' : '80%', margin: '0 auto'}}>
                    <Typography variant={isMobile ? "h4" : "h3"} className='blogTitle'>Trips to Oregon</Typography>
                    <Typography color="textSecondary" className='blogDate' gutterBottom>
                        September 28, 2024
                    </Typography>
                    <Box className='bolgContent'>
                        <Box className='blogText'>
                            <iframe style={{borderRadius: "12px"}}
                                    src="https://open.spotify.com/embed/track/7LSuLsMi4YM3Tk8xwkiKUj?utm_source=generator"
                                    width="100%" height="152" frameBorder="0" allowFullScreen=""
                                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                    loading="lazy" className='musicBlock' title="How It Ends by FINNEAS"></iframe>
                            <Typography variant="body1" className='blogParagraph'>
                                I've taken two trips to Oregon—one in February and another in September. The February
                                trip
                                included some time in Northern California, but it can't take away from the fact that I
                                had a
                                wonderful time in Oregon. The September trip was right before my birthday, and I just
                                figured this was the trip I needed to complete at that time. There were some things I
                                had to
                                see and some people I wanted to meet. Two trips after, Oregon has become one of my
                                favorite
                                getaway places.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                It's going to be a long story but I feel it necessary to talk about the two trips
                                together.
                            </Typography>
                            <Typography ref={fRef} variant="h5" className='blogParagraph'>
                                February
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                The February trip started off strong with chaos and drama. I ended up going on this trip
                                that I originally planned to go alone by myself. I needed to escape from the status I
                                was at
                                and stop worrying about everything for a while. In a strange way, I think it's exactly
                                the
                                chaos and drama that set the stage for everything that followed.
                            </Typography>
                            <Typography ref={f1Ref} variant="h6" className='blogParagraph'>
                                Day 1 Eugene-Crescent City
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                Early morning flight, woke up at 4:30am to catch it. I was flying to Eugene, Oregon, but
                                there weren't any direct flights, so I had to make a stop along the way. Slept through
                                the
                                whole flight but miraculously woke up 10 minutes before landing. This is what I saw when
                                I
                                opened my eyes:
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/1.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Right before landing
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                At that point, I had only one question left in my mind: should I move here?
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                Picked up my rental and started my drive to the Airbnb in Crescent City. It wasn't an easy
                                drive, especially after Grants Pass—mostly winding mountain roads. It was already dark at
                                that time, foggy and rainy, which I assume is typical for that area.
                            </Typography>
                        </Box>
                        {/*<Box*/}
                        {/*    component="img"*/}
                        {/*    className='blogImage'*/}
                        {/*    src={`${process.env.PUBLIC_URL}/blog/oregon/2.jpg`}*/}
                        {/*/>*/}
                        {/*<Typography variant="caption" className='blogCaption'>*/}
                        {/*    First day's driving*/}
                        {/*</Typography>*/}
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                Four and a half hours later, I arrived at my Airbnb: Grandma Patree's house. I have to
                                mention her name because she's such a kind and sweet lady. She gave me the warmest welcome
                                and even baked cookies for me. Oh, the cookies! She made enough for me to take along on my
                                trip. She's an expert on Redwood National Park: printed out trail information for me, found
                                maps, and shared all the must-see spots she could think of.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogSmallImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/3.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Grandma's cookies
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                There's so much happening at Grandma's house. It's vibrant, full of life, interesting and
                                captivating. Everything around the house reflects her strong personality.
                            </Typography>
                        </Box>
                        <Grid container style={{height: '100%', justifyContent: 'center'}}>
                            <Grid item xs={3}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/4.jpg`}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/5.jpg`}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/6.jpg`}
                                />
                            </Grid>
                            <Typography variant="caption" className='blogCaption'>
                                A little peek inside Grandma's house
                            </Typography>
                        </Grid>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                She has a few cats, some chickens, and even a pig. She was so kind that she even prepared
                                carrots for me to feed the pig in the morning.
                            </Typography>
                        </Box>
                        <Grid container style={{height: '100%', justifyContent: 'center'}}>
                            <Grid item xs={4}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/7.jpg`}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/8.jpg`}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/9.jpg`}
                                />
                            </Grid>
                            <Typography variant="caption" className='blogCaption'>
                                Some of other friendly residents
                            </Typography>
                        </Grid>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                It was already 7:30 pm by the time I settled, sorted everything out, and got introduced to
                                every resident of the house. Crescent City was almost asleep by then, with barely any
                                restaurants still open. Grandma Patree found a good spot that still took pickup orders.
                                After hearing about my long day, she kindly offered to pick up the food for me, saying, "At
                                least it's something I can do for you." Born in the 40s and still, she drove to get my food!
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/10.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Grandma Patree on my old crappy point-and-shoot
                        </Typography>
                        <Box className='blogText'>
                            <Typography ref={f2Ref} variant="h6" className='blogParagraph'>
                                Day 2 Redwood NP
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                Redwood National Park day. Breakfast was Grandma's homemade chocolate bread. The best I've
                                ever had. I'm speechless.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                I started with Howland Hill Road, like most visitors, and did some easy hikes along the way.
                                I also made a few stops along the Scenic Parkway, which branches off from Highway 101. I had
                                reserved a permit for Tall Trees Grove, but unfortunately, it was canceled due to a recent
                                storm.
                            </Typography>
                        </Box>
                        <Grid container style={{height: '100%', justifyContent: 'center'}}>
                            <Grid item xs={3}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/11.jpg`}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/12.jpg`}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/13.jpg`}
                                />
                            </Grid>
                            <Typography variant="caption" className='blogCaption'>
                                The redwoods
                            </Typography>
                        </Grid>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                Unless necessary, I avoided highways when driving through the park. Taking the highway takes
                                away all the fun of exploring a forest. As I got closer to Trinidad, most of the roads off
                                the highway led to residential areas, with houses sitting on cliffs overlooking the sea. In
                                my opinion, the views from those homes were far more impressive than any beach or designated
                                viewpoint.
                            </Typography>
                        </Box>
                        <Grid container style={{height: '100%', justifyContent:'center'}}>
                            <Grid item xs={4}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/14.jpg`}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/15.jpg`}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/16.jpg`}
                                />
                            </Grid>
                            <Typography variant="caption" className='blogCaption'>
                                Off the highway
                            </Typography>
                        </Grid>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                As the sun was setting, I came across a trailhead with several cars parked outside. I figured
                                it might be a good spot to explore. The trail was called Elk Head & College Cove. I met a
                                local guy there hiking with his dog and a cool remote-controlled car. Since he knew the way,
                                we decided to hike together.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/17.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            The guy, the dog and the car
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                We watched the sunset, and he took some awesome pictures of his car. On our way back, we
                                headed straight into a creepy forest he mentioned earlier, and I just followed. Sometimes I
                                think blind trust in strangers is such a fascinating thing.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/18.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            By the cliff
                        </Typography>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/19.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Sunset moment in the creepy forest
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                I was holding my film camera back then, and we started wondering if those who develop the film
                                actually look at the photos. I joked that if they do, they could easily track me down. He
                                told me how he once took disposable film cameras to a party. The things they did were so
                                illegal that when the photos came out, he was so glad he and his friends didn't get
                                arrested. I honestly couldn't tell if illegal really means illegal here.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                He recommended the Strawberry Rock Trail to me, saying it was his favorite. Unfortunately, I
                                didn't have enough time to check it out. Before heading to my Airbnb in Arcata, I made a
                                stop in Trinidad. Trinidad State Beach seemed like a great spot for sunset too. I also explored
                                the Scenic Drive starting from Trinidad a bit. It was already getting dark, but I figured
                                the scene would be incredible under the daylight.
                            </Typography>
                            <Typography ref={f3Ref} variant="h6" className='blogParagraph'>
                                Day 3 Lassen Volcanic NP
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                Another long day of driving. I didn't have any plans, just staying in Redding for the night.
                                I was so amazed by the view when driving through the national forests along the way,
                                especially the Six Rivers. It's a pity I couldn't stop to take pictures along the way.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                The weather was so beautiful that day that I made an impromptu decision to visit Lassen
                                Volcanic National Park. I did know that most of the park was closed during winter, with only
                                the road to the visitor center cleared. I also bought tire socks for my rental car, just in
                                case the road conditions got too rough.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                Going there was such a wise decision. I really missed the snow.
                            </Typography>
                        </Box>
                        <Grid container style={{height: '100%', justifyContent: 'center'}}>
                            <Grid item xs={3}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/20.jpg`}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/21.jpg`}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/22.jpg`}
                                />
                            </Grid>
                            <Typography variant="caption" className='blogCaption'>
                                Inside the national park
                            </Typography>
                        </Grid>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/23.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Sunset moment driving downhills
                        </Typography>
                        <Box className='blogText'>
                            <Typography ref={f4Ref} variant="h6" className='blogParagraph'>
                                Day 4 Mt. Shasta
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                This was the day the trip began to feel depressing. The weather was gloomy at first, and as I drove north to Klamath Falls from Redding, the snow started to fall more heavily. I had planned to explore Mt. Shasta, but by the time I reached the town, it was foggy, cloudy and snowy. The sidewalks were slippery, covered in dirty melting ice, and there were hardly any people on the streets—just few stores still open. Typical winter view.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                After several days of traveling, I finally came across a Chinese store. It was just before Chinese New Year, and I was excited to see some festive decorations.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogSmallImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/24.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            A gift store with Chinese New Year decorations
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                A fun story in that town. While walking down the street, I witnessed a pedestrian get knocked down by a car making a turn. I rushed over to check on him, worried about what to do if it turned out to be a hit-and-run. The car stopped, and a woman got out. I could tell she was about to say something harsh, but when she recognized the guy was an old friend of hers, her attitude changed completely. "Oh, it's you! Come on, let's go to the hospital!" I guess that's how you know it's a small town.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                With no mountains to see and nothing else to do, I ended up just driving around. I headed to Lake Siskiyou. The lake was frozen, and it was snowing more heavily. I ventured off the main road a little, but quickly turned around when I realized the roads weren't plowed. Even though I was driving a full-size 4x4 SUV, I was almost certain I'd be stuck if I went any further.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/25.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            A Mt. Shasta viewpoint along Highway 97 with no sight of Mt. Shasta
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                I stayed at Jill's home in Klamath Falls, but she wasn't there due to other arrangements. Her clingy cat jumped on my lap as soon as I arrived and followed me everywhere I went. I spent a lot of time with him.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogSmallImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/26.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Jill's cat, I can't remember his name
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                I found an authentic Chinese place in downtown Klamath Falls called Wong's Cafe. The lady who runs the restaurant is incredibly kind and attentive. She checked up on me, asking how I was managing traveling alone, and expressed genuine concern when I mentioned my plans to visit Crater Lake the next day. She told me where to rent snowshoes and warned me to be careful so many times that I lost count. Enjoying her authentic Chinese food and hearing her motherly nagging made me feel at home, even in a completely unfamiliar city. She seemed to know everyone who came into her restaurant, chatting with them about their recent lives.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogSmallImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/27.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            The fortune cookie I got that day. I could really use some good luck at that time
                        </Typography>
                        <Box className='blogText'>
                            <Typography ref={f5Ref} variant="h6" className='blogParagraph'>
                                Day 5 Crater Lake NP
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                The day finally came—I was heading to Crater Lake National Park. I knew that only a few miles of the road from Annie Spring Entrance Station to Steel Visitor Center were plowed. To get closer to the lake, I'd have to hike 4 miles to the rim. While the chances of seeing the lake during winter are relatively low, I saw people posting pictures of the lake two days ago. This is what I came for, so no doubts, let's just go!
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                The ranger at the Annie Spring Entrance Station ensured I was aware of the road and weather conditions, informing me that I wouldn't be able to see the lake that day since it was already snowing at that altitude. I parked my car in front of the Steel Visitor Center and saw a local couple getting prepared for backcountry skiing. Oregon people are crazy, I thought to myself.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/28.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Driving to the Entrance Station
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                It was not an easy hike; it took much longer than I expected. Over halfway there, the accumulated snow was taller than me. The wind was howling and the weather worsened. It was not only tiring but also quite scary to continue hiking. To make it back to my car before dark, I had to give up. I still had some sanity left in me.
                            </Typography>
                        </Box>
                        <Grid container style={{height: '100%', justifyContent: 'center'}}>
                            <Grid item xs={6}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/29.jpg`}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/30.jpg`}
                                />
                            </Grid>
                            <Typography variant="caption" className='blogCaption'>
                                On my way hiking
                            </Typography>
                        </Grid>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                My former footprints were completely covered by snow when I came back to the car. Beside my car, there were four other fellas parking there. As another car drove by, a lady poked her head out of the window and gave me the look of, "What kind of psychopaths are these people?", and left.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/31.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Five "psychopaths"
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                I returned to downtown Klamath Falls for dinner. I thought I found a seemingly nice place, but the food was some of the worst I've ever had. After a long tiring day of only having cold energy bars, this is what I got. Lesson learned: don't easily trust innovative or fusion cuisine.
                            </Typography>
                        </Box>
                        <Box className='blogText'>
                            <Typography ref={f6Ref} variant="h6" className='blogParagraph'>
                                Day 6 Oakridge
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                Another day without plans. I was staying with Scott near Oakridge. The mountains were still cloudy and snowy, so trying to see Crater Lake again didn't seem worth the effort. On the drive to Oakridge, I passed by Odell Lake—the first non-frozen lake I'd seen in a week.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/32.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Viewpoint by Odell Lake
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                I asked Scott for some travel advice and ended up on the Salmon Creek Trail, which he highly recommended. He even drew me maps, which I later realized were really necessary since the trail isn't marked on Google Maps.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/33.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Though signs here, this trail is not marked on Google Maps
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                It was an easy peaceful hike along Salmon Creek, with no one else on the trail. Even though it's not far from the main road, the animal skeletons I encountered along the way were pretty unsettling!
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogSmallImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/34.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Animal remains
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                It was still early when I finished the hike, so I decided to take a little detour on my way home. I drove up into the mountains, deeper into the woods, determined to keep going as long as the road allowed. I traveled along unpaved gravel roads used for logging, passing by areas where trees had been cut down and stacked. The ride was bumpy, but the views of the mountains were absolutely breathtaking. Since these roads aren't scenic byways, it can be difficult to find a viewpoint that isn't blocked by trees and branches at all. But I can still catch glimpses of the endless mountains through the spaces in between as I drive.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/35.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Photos really can't describe the scene I saw
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                While driving downhill to uptown Oakridge, I was amazed by the overlook of foggy mountains. While people always praise the stunning California forests, have you ever been to Oregon? Unfortunately, with cars behind me and the roads being so narrow, I couldn't stop to take any pictures.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                It was a Tuesday around 4:30pm when I was trying to find something to eat in Oakridge. My phone had no service, and most stores were closed. There were hardly any people on the street. After driving around, I finally found a burger place that was closing at 6pm. Exhausted, I headed back to the Airbnb, and that's where the highlight of my trip began.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/36.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Oakridge uptown graffiti
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                After simple greetings, I started "complaining" to Scott how small the town is. It had taken so much effort just to find a place to eat before dark, and I was getting worried about where I could possibly have dinner. He was so kind that he offered to share his dinner with me.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/37.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            The dinner
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                Then we started talking. I showed him pictures of how snowy it was up at Crater Lake and mentioned how crazy Oregon people are to ski in that weather. He showed great interest: "That looks real nice, gonna go skiing tomorrow."
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                I noticed that none of the bottles, plates, or cups in his house looked like they came from a store. So I asked if he made them, mentioning that I was taking pottery classes so I could tell. He said yes, and then showed me his pottery studio. He's a master. He makes his own glazes, mixing the chemicals himself, and experiments with different techniques. It was amazing, truly amazing. He sells his pieces and is doing good. Every container in his house, any kind you can imagine, was crafted by him. He walked me through his projects, explained how he makes glazes, and shared the thoughts behind his designs. We talked about firing, his favorite pottery artists, and how he built the studio from scratch, learning everything from books little by little. I was honestly out of words. Knowing from my own experience how challenging pottery is—the time, effort, and practice it demands—I was beyond impressed by his work and dedication.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/38.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            His books on pottery
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                Then we just started talking about everything. We had herbal tea. He said that tea felt like wine. He played guitar for me. I was dreaming. We talked till after 3.
                            </Typography>
                        </Box>
                        <Box className='blogText'>
                            <Typography ref={f7Ref} variant="h6" className='blogParagraph'>
                                Day 7 Back home
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                This was the last day of my trip, and the plan was as simple as driving back to Eugene and flying away after waking up. I spent some time at Scott's place taking pictures.
                            </Typography>
                        </Box>
                        <Grid container style={{height: '100%', justifyContent: 'center'}}>
                            <Grid item xs={4}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/39.jpg`}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/40.jpg`}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/41.jpg`}
                                />
                            </Grid>
                            <Typography variant="caption" className='blogCaption'>
                                Corners of Scott's place
                            </Typography>
                        </Grid>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                I could share more, but I'd like to keep some of those special memories just for myself.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                All the decorations and furnishings in his home have a lively vibe, showing how much care he put into decorating and setting up the space. You can see his personality in every little item he's placed around. I'm so grateful I made the decision to stay with him. I met the best person by the end of this not-so-lucky trip.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/42.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Scott
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                He cooked breakfast for me that morning, using mushrooms picked by himself.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogSmallImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/43.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Breakfast by the chef
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                We snapped Polaroids together before I left, and he put it on the windowsill of his pottery studio. I kept mine on my desk. He also gave me a cup he made. That's my precious, I will keep it to myself.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogSmallImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/44.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            The Polaroid
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                I had asked Jill many questions about Crater Lake and the local conditions before I arrived, but unfortunately, I didn't get to see the lake during my visit. A few days after I returned home, I received her review of me on Airbnb.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogSmallImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/45.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            "Hopefully crater lake will be something still on your bucket list"
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                Now I absolutely have to see this lake.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                One of my favorite singers Finneas, has a song called How It Ends, with a line that goes, "If you wanna see the world, don't take the highway." Exactly, not the highway—that's where I found the best views. I couldn't stop earworming this song throughout the trip.
                            </Typography>
                            <Typography ref={sRef} variant="h5" className='blogParagraph'>
                                September
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                I've been dreaming about this trip ever since I got back from Oregon. Scott had asked me when I planned to return to the Pacific Northwest, and I finally made it happen in September. I can't even describe how excited I was—finally getting the chance to explore this beautiful state during such a perfect time of year.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                And I miss Scott.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                I'm really proud of this trip. I also made sure to take some time for myself and really enjoy the experience. I was a bit concerned before though because I had never driven such a long time in a single day.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/46.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Roughly my driving route, from A-I, marked are places I stayed for night
                        </Typography>
                        <Box className='blogText'>
                            <Typography ref={s1Ref} variant="h6" className='blogParagraph'>
                                Day 1 Arrive
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                The flight landed in Portland. Portland gives a totally different vibe than Eugene. Honestly, I had some stereotypes about the city before coming, imagining it as more rural. I had heard stories about the aggressive homeless in downtown and expected the area to feel a bit rough. At the same time, I pictured Portland with that typical Oregon charm—green, beautiful, peaceful. The buildings were surprising, and the highways and bridges looked incredibly modern and well-developed. And the traffic! I jumped right into it as soon as I left the airport.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                Saw a Porsche with ribbons wrapped around it at the airport. Don't know who's gonna be the lucky guy that day.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                I drove straight to Scott's place and we started catching up on each other's lives. When we spent half an hour discussing when we usually go to sleep, I knew we were so screwed. We took a sunset walk and ran into one of his neighbors practicing archery. He's a fireman busy working on the fires in the surrounding areas. I asked Scott if he knew all his neighbors since it's such a small community, he said that there were some hermits who rarely go outside and prefer to be left alone. He thinks if you wave to them and they don't wave back, that's a hermit. The fireman has lived there for four years, but Scott had never seen him before. So I reckon, hermit! Scott picked some berries from the bushes by the road. I've never had such good blackberries before.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/47.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Walk by the river
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                The chef made dinner again. It was already after 9pm when we finally sat down to eat. He asked me why it always gets so late when we're spending time together.
                            </Typography>
                            <Typography ref={s2Ref} variant="h6" className='blogParagraph'>
                                Day 2 With Scott
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                Whole day spent with Scott! Since we had a lot of outdoor activities planned, the chef prepared a huge breakfast.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogSmallImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/48.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            The breakfast
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                We went kayaking at Waldo Lake, one of the purest freshwater lakes in the world. I was in Scott's whitewater kayak, and I have to say, it felt completely different from the rental kayaks I've paddled before. Took me a while to stop spinning around. I hadn't been paddleboarding or kayaking in a while, being close to the water again was just so calming. For no clear reason, I was earworming Have You Had Enough Wine the entire time.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogSmallImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/49.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Kayaking at Waldo Lake
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                Next, we headed to Salt Creek Falls. It was an amazing waterfall that reminded me of Iceland, where one of the main attractions is endless waterfalls. This one barely required any hiking. We went deeper into the woods and came across another waterfall that only required 1.5-mile extra hike. He asked me if we should go for it and I said, "Why not?" On our way to the waterfall, he kept pointing out every mushroom he saw along the trail. Oh forgot to mention—he's a biologist fascinated with mushrooms.
                            </Typography>
                        </Box>
                        <Grid container style={{height: '100%', justifyContent: 'center'}}>
                            <Grid item xs={6}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/50.jpg`}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/51.jpg`}
                                />
                            </Grid>
                            <Typography variant="caption" className='blogCaption'>
                                Mushrooms we found along the way
                            </Typography>
                        </Grid>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                When we were there, we spent some time just standing there staring at the waterfall. It was so peaceful that I felt like I was on another planet. All my troubles and worries seemed to disappear at that moment. Being close to nature really makes you feel good about yourself.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/52.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Diamond Creek Waterfall
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                I made a pot for him that's very much me, even though I, the maker, am not sure what it's for. Both the shape and the glaze are my style. It feels like the perfect spot for it. The plants and lighting around? I mean WOW.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogSmallImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/53.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            My pot is next to the plants
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                Time to say goodbye. I didn't leave too late to avoid driving in the dark. At the same spot as last time, I noticed lots of cars parking at the viewpoint. I stopped and looked back, saw this sunset.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/54.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Diamond Peak lookout point sunset
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                It was already 9:30pm when I arrived at my Airbnb in Bend, and finding a place to have dinner at that time wasn't easy. Luckily, Olive Garden was still open for guests. I had never been to Olive Garden before, even though it seems to be everywhere. At that point, I was just grateful to have a meal that wasn't fast food. But to be honest, their soup taste like puke after adding cheese. Absolutely no next time.
                            </Typography>
                            <Typography ref={s3Ref} variant="h6" className='blogParagraph'>
                                Day 3 Mt. Hood
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                Mt. Hood only day. Thanks again for the beautiful weather! I knew I could hike up Mt. Hood from Timberline Lodge, but considering the activities I had done the day before, I just wanted to relax and see what I could do. I didn't realize that the chair lift to the snowy area of the mountain was still open. When I checked with the staff, they mentioned it was one of the last few days the lift would be operating. Lucky! Being above the clouds felt so surreal.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/55.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Up above the clouds at Mt. Hood
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                Then I saw bike rentals. That would be a nice day for biking! I knew it was mountain biking, but they mentioned there were easy trails for beginners. I even saw small kids riding. I thought to myself: this is just gonna be a peaceful and easy ride. I got the bike, grabbed the gear, let's go!
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/56.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Mountain biking
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                It was terrifying at first—the sudden turns and steep slopes made me question how they could call it an easy trail. Before I went, they told me there were no drops on this trail, but after going down three steps that were at least 30 cm high, I parked my bike and started wondering, "Americans have some weird standards for easy." I gave way to everyone I encountered, explaining that it was my first time and that I was going slow. I must have lost it on some of the steps. I don't know what happened but hell yeah! It eventually got better. I felt much more confident and comfortable after a while.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                The bike trail was a downhill route. Once I reached the bottom of the mountain, I could take the chair lift back up. That chair lift can take bikes too! My legs were swinging outside the chair. I'm glad I survived and that I'm alive to experience this.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/57.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            On the lift with my bike
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                After getting down from the lift, there was still a short ride back to the starting point. Just as I was about to head out, I noticed a dad with two young boys getting ready to go. The dad asked if I wanted to go first, and I went, "Absolutely not, you go ahead." He smiled and said, "Well, don't be afraid, you've gone this far." I explained it was my first time and that I was just slow. Then his kids shouted, "You look like a pro!"
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                Had my "glad-to-be-alive" taco at a diner afterward. Ran into a guy I had chatted with before and ended up sitting with him. We shared stories: me about my trip, him about his hike and the view he woke up to from his hammock. It started off great, but he got a little creepy later. So I'll just cut it here.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                It was a long drive that day, all the way to Washington for the night. Passing through Hood River, I saw so many farms and yards. I've had cherries from Hood River before—so good. I imagine the other fruit must be just as amazing. There were plenty of vineyards offering wine tastings. Even though I've never been to Napa Valley, I have a feeling the wine in Hood River might be even better. I've always loved lovely family-owned businesses. I wish I had more time to explore Hood River.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/58.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            A random viewpoint at Hood River
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                I stopped by Hood River Waterfront Park and watched people kiteboarding. It struck me how, even when you have so much on your mind, there are people out there enjoying themselves carefree. Somehow, despite being so different, we all ended up in the same place.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                It was Labor Day, and I didn't arrive at my Airbnb until after 8. It was a tiny town with nothing around. I had to drive at least 20 minutes just to find a place to eat. Of course, everywhere was packed that night. My hosts were a lovely older couple who had seven hikers staying at their place already. When I asked the hostess if she knew of any nearby places to grab a bite, she kindly offered me some of their leftover dinner. She had even bought a berry pie for the hikers, and I got to have a slice. The pie won everyone's approval.
                            </Typography>
                        </Box>
                        <Grid container style={{height: '100%', justifyContent: 'center'}}>
                            <Grid item xs={6}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/59.jpg`}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/60.jpg`}
                                />
                            </Grid>
                            <Typography variant="caption" className='blogCaption'>
                                The dinner and the pie
                            </Typography>
                        </Grid>
                        <Box className='blogText'>
                            <Typography ref={s4Ref} variant="h6" className='blogParagraph'>
                                Day 4 Columbia River Gorge & Cannon Beach
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                I have to admit my schedule was pretty tight. The plan for the day was to hike through the waterfalls in the Columbia River Gorge and then drive to Cannon Beach to catch the sunset before staying in Nehalem for the night.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                I wasn't planning on heavy hikes, the only long one I did was Multnomah Falls, which is the most popular waterfall. If you're reading this blog, park your car on the other side of the highway! It costs $20 or $25 to park right next to the waterfall. I couldn't even believe my eyes when I saw the price. Waterfalls are waterfalls, they are beautiful, nothing much to talk about. My favorite was Bridal Veil Falls. My earworm throughout the hikes was "I will burn down this city".
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/61.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Bridal Veil Falls
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                After that, I went to Vista House and the Portland Women's Forum State Scenic Viewpoint, which both offer stunning views of the Columbia River Gorge. After I finished the planned trip, I figured I still had time. So I checked the map and found a better overlook point just 30 minutes drive away. Why not!
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/62.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Vista house
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                I saw a "Road Closed in 8 Miles" sign while driving up. Since the drive is 13 miles long in total, I thought "There might still be something to see". It turned out to be a false alarm. The drive was dreamy, with light streaming through the trees and steam rising from the wet road like mist. I barely saw any cars passing by, so I took my time, driving slowly to enjoy the moment.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                The viewpoint is called Sherrard Point. When I parked my car and hiked there, I was surrounded by those famous snowy volcanoes: Mt. Jefferson, Mt. Hood, Mt. St. Helens, Mt. Adams, and Mt. Rainier. The drive was already worth the effort, and the view up there was just mind-blowing!
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/63.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Mt. Hood from another angle
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                Time for Cannon Beach! It was one of the spots I looked forward to the most. I could already see Haystack Rock in the distance from Highway 101. Still 30 minutes to sunset when I got there. I rushed to the beach. OH MY GOD! I can't describe how spectacular it was. Just check the photos!
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/64.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Haystack Rock from distance
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                There were so many people carrying fancy cameras on the beach, all waiting for the sunset moment. People were running on the beach, dogs were running on the beach, I was running on the beach. I couldn't stop pressing my shutter button, even though you know how precious film is. My friend said that the scene looked so surreal it felt like we weren't even in the US. I agreed. One of the rocks looks like the one on the black sand beach in Iceland. Everyone was running, laughing, holding hands, and staring at the sinking sun.
                            </Typography>
                        </Box>
                        <Grid container style={{height: '100%', justifyContent: 'center'}}>
                            <Grid item xs={9}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/66.jpg`}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/65.jpg`}
                                />
                            </Grid>
                            <Typography variant="caption" className='blogCaption'>
                                Sunset moment at Cannon Beach
                            </Typography>
                        </Grid>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                I also learned that there are puffins here at Cannon Beach, though they're a different species from the ones in Iceland. Just another reason why Oregon feels like Iceland! Puffins are such beautiful creatures.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/67.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Cannon Beach is also home for Puffins
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                After dinner at a nice restaurant, I headed back to my place in Nehalem. I thought Cannon Beach was the highlight of the day, but I was wrong. When I parked my car, the host's cat jumped onto my windshield, welcoming me back. I stepped out of the car, unconsciously raised my head, saw the Milky Way stretching right above me.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/68.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            My host's cat
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                The house was on a small hill, so there wasn't much light pollution other than the lights in front of each home. I quickly put my stuff down and rushed outside to capture the moment. It was absolutely stunning, stars were just above the horizon. I couldn't tell what kind of dream I was in.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/69.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            The Milky Way with GoPro
                        </Typography>
                        <Box className='blogText'>
                            <Typography ref={s5Ref} variant="h6" className='blogParagraph'>
                                Day 5 Highway 101
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                The ultimate long drive day! I had a 7.5-hour drive ahead of me, starting with a stop in Manzanita for my morning coffee. On the way, I saw small canoes drifting on the river and people fishing. The scene was so peaceful that it felt like they didn't belong to this planet. Manzanita had many interesting stores that I would have loved to explore but sadly, tight schedule.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                I drove along Highway 101, trying to stay as close to the sea as possible. The cliffs by the shore and the huge rocks sticking out from the surface were spectacular. I made quick stops at a few lighthouses and Thor's Well, but what I actually aimed for was the sunset at Natural Bridges near Brookings. Along the way, I passed through several towns that seemed like they had so much to explore, especially those north of the Oregon Dunes. If I want to do it right, I'll spend at least three days exploring the Oregon part of Highway 101 next time.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                I made it to Natural Bridges, breathing heavily after running from the parking spot to the viewpoint. There, I met a lady who was also enjoying the sunset. She told me it was her favorite spot.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/70.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Natural Bridges at dusk
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                As I left, I noticed a side trail, not knowing where it led. I went ahead for some distance, saw the sun casting through the branches, golden.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/72.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            At a random trail
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                We ended up meeting again at the next viewing point. We watched the sunset together, talking and sharing.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/71.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Sunset at Samuel H. Boardman State Scenic Corridor
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                I still had to drive for a while after dark since I was staying at Smith River in California. Fun fact: there's a cannabis store right at the state line between Oregon and California called Stateline Cannabis. As you drive from Oregon to California, you see the "Welcome to California" sign. However, when crossing back into Oregon, there are huge signs for Stateline Cannabis, but not a single "Welcome to Oregon" sign.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                Another night of struggling to find dinner. From my previous experience, I knew I wouldn't have any luck in Crescent City, so I headed north to a pub called Lucky Pirate, which I called earlier was still open. There were strange people there: some high, others stumbling or gambling. However, the bartender was super nice and kept calling me "darling". He was playing Down by Marian Hill inside. I said I hadn't heard this song for ages. The burger I ordered was delicious! That night, I felt like a lucky pirate because I found something good to eat.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/73.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Burger from Lucky Pirate's
                        </Typography>
                        <Box className='blogText'>
                            <Typography ref={s6Ref} variant="h6" className='blogParagraph'>
                                Day 6 Crater Lake NP
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                Aiming for Crater Lake once more. There were fires around the area. The weather report said the wind would blow the smoke away from Crater Lake that afternoon. It seemed promising, but either way I was going. I had to drive along Highway 199 and pass through Grants Pass, the same route I took from Eugene to Crescent City earlier this year. But this time, the drive felt a lot easier.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                Oh, Crater Lake. The Rim Drive I walked on last time is fully open now, with all the snow gone. I drove up to the visitor center at Rim Village to grab the unigrid map, but as soon as I saw the lake, I was completely captivated. This is what I came for, and why I came back. It's worth every effort, every wait, and every bit of expectation.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/74.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            First impression of Crater Lake. Still fire going on far away
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                Standing at the rim, I messaged Jill: "You might not remember me, but I want you to know that I'm here, I finally saw the lake."
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/75.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            I messaged Jill
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                Part of the Rim Drive was closed, so I try to drive every accessible road. It's just a drive around a lake, viewing it from different angles, but wow, WOW! I didn't have the energy to hike down the Cleetwood Cove Trail to the water body, which is a bit pity.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                I took this picture at Phantom Ship Overlook. I brought my Jellycat cake plush with me. Funny story about this cake. While staying at an Airbnb in the Bay Area earlier, my host's roommate's husky opened my room's door while I was sleeping. He snuck into my room, grabbed the cake from my suitcase, and made it his. It took me some effort to fight it back from him. He was very protective, I was stuck there for at least two hours. There was no one at home at that point except for me and the husky, so no one knew the full story. My host later told me, "He won't come into your room if you close the door." Well, I guess it will be a forever mystery.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogVerticalImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/76.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            My cake on my film camera
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                My Airbnb host in Chiloquin asked when I'd be back that day. I said I was not sure, cause when I go downhill depends on if sunset is happening. She agreed, "That's true, you probably only get one shot. Better enjoy it". I came back to Rim Village waiting for sunset, but it was kinda hopeless. Plus, I hadn't had a proper dinner at regular dinner time for days. And I missed Chinese food. So I left, drove further south to Klamath Falls, back to the same place as before—Wong's Cafe.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/77.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            Hopeless sunset
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                The moment I stepped into the restaurant, that kind lady looked at me and asked, "Have you ever been here before?" I said yes, back in February. She recognized me! We started catching up on each other's lives, she asked a lot about me. I ordered some very authentic Chinese food, and they tasted just like home. I finished the entire plate of egg-fried rice. One of the chefs said, "I've never seen anyone finish that plate before. Girl you can eat!" I was too full to finish the other dish. The lady kept apologizing, saying it wasn't good enough. I felt guilty.
                            </Typography>
                        </Box>
                        <Grid container style={{height: '100%', justifyContent: 'center'}}>
                            <Grid item xs={6}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/78.jpg`}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Box
                                    component="img"
                                    className='blogGridImage'
                                    src={`${process.env.PUBLIC_URL}/blog/oregon/79.jpg`}
                                />
                            </Grid>
                            <Typography variant="caption" className='blogCaption'>
                                My satisfying dinner
                            </Typography>
                        </Grid>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                After the meal, I got two more fortune cookies. I still keep the one I got from here before in my phone case, and the new ones were just as encouraging and inspiring.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                I felt so touched that a total stranger still remembered me and genuinely cared about me. She is so sweet and hardworking. I'm really glad I got to know her. We exchanged numbers, and she thanked me for driving all the way there to eat at her restaurant. She kept saying please come back, and offered to help if I ever had any problems. She said "thank you" so many times that I lost count.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                My Airbnb was in a forest, surrounded by trees. By the time I arrived, my host had already fallen asleep. I stumbled through the big house trying to find the bathroom, remembering the tip she had sent earlier: The bathroom is 26 steps from your room. I counted as I walked, exactly 26 steps. Very precise.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/80.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            I think this place is called Oasis Retreat
                        </Typography>
                        <Box className='blogText'>
                            <Typography ref={s7Ref} variant="h6" className='blogParagraph'>
                                Day 7 Two Amazing Humans
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                I didn't have anything planned for the day, only driving to Salem to stay for the night. Scott's work got pushed back, so he was still at home. I had to stop by since I'd be driving past his place. As I drove along Highway 97, memories started flooding back. I went the same way in February, I remembered every town. The smoke got thick as I turned onto Highway 58, the sky was almost orange. I felt lucky. The fire only started getting worse when I was about to leave.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/81.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            The smoke near Oakridge
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                He was packing up for work. We started talking about my trip in the past week, his upcoming work, his recent studio work and all others. Scott is an amazing person with incredible life experiences. I wish I could share more about him. But I don't think I should be the one who tells his stories. He's the boss. Also I do think that the reason I know these stories is because I am who I am. I'd prefer to keep those stories to myself. He is so supportive and encouraging, and such an inspiration to me. I'm lucky to know him.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                Before I left, we were hugging. I said "It's so nice to see you again". He asked me "When can I see you again?"
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                I thought the story would end here. Before I drove to Salem, my host Chris asked me when I would arrive. I told her the approximate time and that I was looking forward to meeting her. She said she would be working late until after 11pm, I might be asleep by then. I told her I tend to sleep late, we'll see.
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                After such an exhausting week, I ordered takeout for dinner because I didn't have the strength to drive out. I was sleepy, but I didn't go to sleep. At around 11:30, she knocked on my door, asking me how I was with those sincere eyes. We hugged, it felt like the comfort we both needed. She said she had to work hard so she could buy this beautiful house. I said it was the end of my trip, I was so tired. She is so cute, sweet and soft. We wished each other good night.
                            </Typography>
                            <Typography ref={s8Ref} variant="h6" className='blogParagraph'>
                                Day 8 Leaving
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                Last day. I drove back to Portland and was ready to leave. At the airport, I saw this magnet and bought it without a second thought. Indeed, I met so many wonderful people along the way. I drove over 1,600 miles in 7 days, and I enjoyed every bit of it. I'm glad I was on my way this February and that I'm back this September. Every single moment felt like a dream.
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            className='blogSmallImage'
                            src={`${process.env.PUBLIC_URL}/blog/oregon/82.jpg`}
                        />
                        <Typography variant="caption" className='blogCaption'>
                            The magnet
                        </Typography>
                        <Box className='blogText'>
                            <Typography variant="body1" className='blogParagraph'>
                                Oh, by the way, make sure to check your rental before leaving the airport. The sticker on my rental was expired in April 2024. The guy who was responsible for my return was so terrified when I told him about it. He asked me if I got pulled over, I said luckily no.
                            </Typography>
                            <Typography ref={endRef} variant="h5" className='blogParagraph'>
                                The End
                            </Typography>
                            <Typography variant="body1" className='blogParagraph'>
                                I felt like I finally completed the task I started back in February. Those bad luck are not all so bad after all. The hard times I've been through paid off and gave me a somewhat happy ending before my birthday. Fresh new start from now on.
                            </Typography>
                        </Box>
                    </Box>
                    <IconButton
                        onClick={openDrawer(true)}
                        variant="contained"
                        sx={{
                            position: 'fixed',
                            bottom: 20,
                            right: 70,
                            zIndex: 1000,
                            backgroundColor: 'rgba(255, 255, 255, 0.85)',
                            "&:hover": {backgroundColor: 'rgba(229,229,229,0.85)'}
                        }}
                        id="back-to-top-btn"
                    >
                        <MenuIcon sx={{fontSize: 30}}/>
                    </IconButton>
                    <Drawer open={open} onClose={openDrawer(false)}>
                        {menuDrawer}
                    </Drawer>
                </Box>
            }
        </Box>
    );
};

export default TripsToOregon;
